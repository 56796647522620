import { useGameContext } from "../../Contexts/GameContext";
import { bufferCommandAction } from "../../State/Actions/commands";
import { toCapitalize } from "../../Util/toCapitalize";

function Npc({ token, capitalize, ...props }) {
  const [, dispatch] = useGameContext();

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(bufferCommandAction(token.uid));
  };

  let prefix = token.determiners.indefinite;
  if (prefix && capitalize) {
    prefix = toCapitalize(prefix);
  }
  let name = token.name;
  if (!prefix && capitalize) {
    name = toCapitalize(name);
  }

  return (
    <span {...props}>
      {prefix}&nbsp;

      <a href="#npc" className="hyperlink hyperlink-npc" onClick={handleClick}>
        {name}
      </a>
    </span>
  );
}

export default Npc;
