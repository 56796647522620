import Form from "react-bootstrap/Form";
import {
  backwardHistoryAction,
  bufferCommandAction,
  forwardHistoryAction,
  sendCommandAction,
} from "../State/Actions/commands";
import { useGameContext } from "../Contexts/GameContext";
import { useWebSocketContext } from "../Contexts/WebSocketContext";

function CommandInput() {
  const [state, dispatch] = useGameContext();
  const [sendCommandProtocol] = useWebSocketContext();

  const handleKeyPress = (event) => {
    if (event.key === "ArrowUp") {
      dispatch(backwardHistoryAction());
    }
    if (event.key === "ArrowDown") {
      dispatch(forwardHistoryAction());
    }
    if (event.key === "Enter") {
      event.preventDefault();
      sendCommandProtocol(state.command.buffer);
      dispatch(sendCommandAction());
    }
  };

  const handleCommandText = (event) => {
    dispatch(bufferCommandAction(event.target.value));
  };

  return (
    <section className="section-command">
      <Form>
        <Form.Control
          type="text"
          
          className="command-input"
          value={state.command.buffer}
          placeholder="What do you want to do next?"
          onChange={handleCommandText}
          onKeyDown={handleKeyPress}
        />
      </Form>
    </section>
  );
}

export default CommandInput;
