import { toCapitalize } from "../../Util/toCapitalize";

function Player({ name, ...props }) {
  return (
    <span {...props}>
      <span className="hyperlink hyperlink-player">
        {toCapitalize(name)}
      </span>
    </span>
  );
}

export default Player;
