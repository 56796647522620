import { createContext, useContext } from "react";
import {
  receiveActionProtocol,
  receiveDescriptionProtocol,
  receiveDialogProtocol,
  receivePromptProtocol,
  receiveRoomProtocol,
  receiveSystemMessageProtocol,
} from "../State/Actions/messages";
import { useGameContext } from "./GameContext";

let ws;

const WebSocketContext = createContext();

export const useWebSocketContext = () => useContext(WebSocketContext);

export const WebSocketContextProvider = ({ children }) => {
  const [, dispatch] = useGameContext();

  const session = sessionStorage.getItem("session");

  if (!ws) {
    const query = session ? `?session=${session}` : "";
    ws = new WebSocket(process.env.REACT_APP_WS_URL + query);
  }

  ws.onopen = () => {
    dispatch(
      receiveSystemMessageProtocol({
        type: "system",
        message: "You are connected to Aseavria.",
      })
    );

    setInterval(() => {
      ws.send(JSON.stringify({ type: "ping" }));
    }, 30 * 1000);

    ws.onclose = () => {
      dispatch(
        receiveSystemMessageProtocol({
          type: "system",
          message: "You have been disconnected from Aseavria.",
        })
      );
    };
  };

  ws.onmessage = (message) => {
    try {
      const protocol = JSON.parse(message.data);
      console.log(protocol);
      switch (protocol.type) {
        case "ping":
          return;
        case "action":
          dispatch(receiveActionProtocol(protocol));
          return;
        case "description":
          dispatch(receiveDescriptionProtocol(protocol));
          return;
        case "dialog":
          dispatch(receiveDialogProtocol(protocol));
          return;
        case "prompt":
          dispatch(receivePromptProtocol(protocol));
          return;
        case "room":
          dispatch(receiveRoomProtocol(protocol));
          return;
        case "system":
          dispatch(receiveSystemMessageProtocol(protocol));
          return;
        case "session":
          updateSession(protocol.session);
          return;
        default:
          console.warn("Unrecognized protocol message", protocol);
      }
    } catch (err) {
      console.warn("Failed to parse protocol", message, err);
    }
  };

  const sendCommandProtocol = (command) => {
    ws.send(JSON.stringify({ type: "command", command }));
  };

  const updateSession = (session) => {
    sessionStorage.setItem("session", session);
  };

  return (
    <WebSocketContext.Provider value={[sendCommandProtocol]}>
      {children}
    </WebSocketContext.Provider>
  );
};
