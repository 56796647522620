import { ACTION_BACKWARD_HISTORY, ACTION_BUFFER_COMMAND, ACTION_FORWARD_HISTORY, ACTION_SEND_COMMAND } from "../Actions/commands";
import { truncateStart } from "../../Util/truncate";

export const commandsReducer = (state, action) => {
  switch (action.type) {
    case ACTION_BUFFER_COMMAND:
      state.command.buffer = action.command;
      state.history.position = state.history.commands.length;
      break;
    case ACTION_SEND_COMMAND:
      // Save the command history if not the same as the last command
      const command = action.command ?? state.command.buffer
      if (
        state.history.commands[state.history.commands.length - 1] !==
        command
      ) {
        state.history.commands.push(command);
        truncateStart(state.history.commands, state.history.maxSize);
      }
      state.history.position = state.history.commands.length;
      state.command.buffer = "";
      state.command.temp = "";
      break;
    case ACTION_BACKWARD_HISTORY:
      // Save the buffer to a temp buffer if this is the first step backward
      if (state.history.position === state.history.commands.length) {
        state.command.temp = state.command.buffer;
      }
      state.history.position -= 1;
      if (state.history.position < 0) {
        state.history.position = 0;
      }
      state.command.buffer = state.history.commands[state.history.position];
      break;
    case ACTION_FORWARD_HISTORY:
      state.history.position += 1;
      if (state.history.position >= state.history.commands.length) {
        state.history.position = state.history.commands.length;
        state.command.buffer = state.command.temp;
        break;
      }
      state.command.buffer = state.history.commands[state.history.position];
      break;
    default:
  }
};
