import MessageView from "./MessageView";

function MainView() {
  return (
    <>
      <MessageView />
    </>
  );
}

export default MainView;
