import {
  ACTION_RECEIVE_ACTION_PROTOCOL,
  ACTION_RECEIVE_DESCRIPTION_PROTOCOL,
  ACTION_RECEIVE_DIALOG_PROTOCOL,
  ACTION_RECEIVE_PROMPT_PROTOCOL,
  ACTION_RECEIVE_ROOM_PROTOCOL,
  ACTION_RECEIVE_SYSTEM_MESSAGE_PROTOCOL,
} from "../Actions/messages";
import { truncateEnd } from "../../Util/truncate";

export const messagesReducer = (state, action) => {
  switch (action.type) {
    case ACTION_RECEIVE_ROOM_PROTOCOL:
      state.room = action.protocol.room;
      state.main.historical.unshift(...state.main.current);
      truncateEnd(state.main.historical, state.main.maxSize);
      state.main.current = [];
      state.main.current.unshift(action.protocol);
      if (action.protocol.room.background !== "") {
        state.main.current.unshift({
          id: `${action.protocol.id}-background`,
          type: "description",
          message: action.protocol.room.background,
          perspective: "background",
          tokens: action.protocol.tokens,
        });
      }
      action.protocol.room.npcs.forEach((npc, i) => {
        state.main.current.unshift({
          id: `${action.protocol.id}-npc-${i}`,
          type: "action",
          action: npc,
          tokens: action.protocol.tokens,
        });
      });
      action.protocol.room.players.forEach((player, i) => {
        state.main.current.unshift({
          id: `${action.protocol.id}-player-${i}`,
          type: "action",
          action: player,
          tokens: action.protocol.tokens,
        });
      });
      break;
    case ACTION_RECEIVE_ACTION_PROTOCOL:
    case ACTION_RECEIVE_DESCRIPTION_PROTOCOL:
    case ACTION_RECEIVE_DIALOG_PROTOCOL:
    case ACTION_RECEIVE_PROMPT_PROTOCOL:
    case ACTION_RECEIVE_SYSTEM_MESSAGE_PROTOCOL:
      state.main.current.unshift(action.protocol);
      break;
    default:
  }
};
