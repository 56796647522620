import produce from "immer";
import { commandsReducer } from "./commands";
import { messagesReducer } from "./messages";

export const initialState = {
  room: { id: null, name: "", summary: "", description: "", exits: [], players: [], npcs: [], items: [] },
  main: {
    current: [],
    historical: [],
    maxSize: 50,
  },
  history: {
    commands: [],
    position: -1,
    maxSize: 50,
  },
  command: {
    buffer: "",
    temp: "",
  },
};

export const reducer = produce((state, action) => {
  commandsReducer(state, action);
  messagesReducer(state, action);
});
