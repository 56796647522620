export const ACTION_BUFFER_COMMAND = "ACTION_BUFFER_COMMAND";
export const ACTION_SEND_COMMAND = "ACTION_SEND_COMMAND";
export const ACTION_FORWARD_HISTORY = "ACTION_FORWARD_HISTORY";
export const ACTION_BACKWARD_HISTORY = "ACTION_BACKWARD_HISTORY";

export const bufferCommandAction = (command) => {
  return {
    type: ACTION_BUFFER_COMMAND,
    command,
  }
}

export const sendCommandAction = (command) => {
  return {
    type: ACTION_SEND_COMMAND,
    command,
  }
}

export const forwardHistoryAction = () => {
  return {
    type: ACTION_FORWARD_HISTORY,
  }
}

export const backwardHistoryAction = () => {
  return {
    type: ACTION_BACKWARD_HISTORY,
  }
}
