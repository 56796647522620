import { createContext, useContext, useReducer } from "react";

const GameContext = createContext();

export const useGameContext = () => useContext(GameContext);

export const GameContextProvider = ({ children, initialState, reducer }) => {
  const [globalState, dispatch] = useReducer(reducer, initialState);

  return (
    <GameContext.Provider value={[globalState, dispatch]}>{children}</GameContext.Provider>
  );
};
