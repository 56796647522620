import { useGameContext } from "../Contexts/GameContext";
import Message from "./Message";

function MessageView() {
  const [state] = useGameContext();

  const characters = new Set();

  const hasSource = (sources) => {
    for (const source of sources) {
      if (!characters.has(source)) {
        return false;
      }
    }
    return true;
  };

  const addSource = (sources) => {
    for (const source of sources) {
      characters.add(source);
    }
  };

  return (
    <section className="section-messages">
      {state.main.current.map((m, i) => {
        let historical = false;
        if (m.type === "action") {
          historical = hasSource(m.action.source);
          addSource(m.action.source);
        }
        return (
          <Message
            key={m.id ?? `c${i}`}
            type={m.type}
            protocol={m}
            historical={historical}
          />
        );
      })}
      {state.main.historical.map((m, i) => {
        return (
          <Message
            key={m.id ?? `h${i}`}
            type={m.type}
            protocol={m}
            historical={true}
          />
        );
      })}
    </section>
  );
}

export default MessageView;
