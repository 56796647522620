
import { useGameContext } from "../../Contexts/GameContext";
import { useWebSocketContext } from "../../Contexts/WebSocketContext";
import { sendCommandAction } from "../../State/Actions/commands";

function Exit({ direction, ...props }) {
  const [, dispatch] = useGameContext();
  const [sendCommandProtocol] = useWebSocketContext();

  const handleClick = (e) => {
    e.preventDefault();
    const command = `go ${direction}`;
    sendCommandProtocol(command);
    dispatch(sendCommandAction(command));
  };

  return (
    <span {...props}>
      <a href="#exit" className="hyperlink hyperlink-exit" onClick={handleClick}>
        {direction}
      </a>
    </span>
  );
}

export default Exit;
