
function You({ ...props }) {
  return (
    <span {...props}>
      <a href="#you" className="hyperlink hyperlink-you">You</a>
    </span>
  );
}

export default You;
