import { useMemo } from "react";
import reactStringReplace from "react-string-replace";
import Exit from "./Inline/Exit";
import Npc from "./Inline/Npc";
import Player from "./Inline/Player";
import You from "./Inline/You";

function ActionMessage({ protocol, historical }) {
  const { action, tokens } = protocol;

  const classNames = ["main-message", "main-message-action"];
  if (historical) {
    classNames.push("historical");
  }
  return (
    <div className={classNames.join(" ")}>
      {_toParagraphs(action.description, tokens)}
    </div>
  );
}

function DescriptionMessage({ protocol, historical }) {
  const { message, tokens, perspective } = protocol;
  const classNames = ["main-message", "main-message-description"];

  if (perspective === "background") {
    classNames.push("background");
  }

  if (historical) {
    classNames.push("historical");
  }
  return (
    <div className={classNames.join(" ")}>{_toParagraphs(message, tokens)}</div>
  );
}

function DialogMessage({ protocol, historical }) {
  const { dialog, tokens } = protocol;
  const classNames = ["main-message", "main-message-dialog"];

  if (historical) {
    classNames.push("historical");
  }
  return (
    <div className={classNames.join(" ")}>{_toParagraphs(dialog.message, tokens)}</div>
  );
}

function Room({ protocol, historical }) {
  const { room, tokens } = protocol;
  const classNames = ["main-message", "main-message-room"];

  if (historical) {
    classNames.push("historical");
  }
  return (
    <div className={classNames.join(" ")}>
      <p>
        <span className="room-name">{room.name}</span> [{room.area}]
      </p>

      <div className="room-description">
        {_toParagraphs(room.description, tokens)}
      </div>

      <div className="room-exits">
        {_toParagraphs(room.exits, tokens)}
      </div>
    </div>
  );
}

function SystemMessage({ protocol, historical }) {
  const { message, tokens } = protocol;

  if (historical) {
    return <div />;
  }

  return (
    <div className="main-message main-message-system">
      {_toParagraphs(message, tokens)}
    </div>
  );
}

function Prompt({ protocol, historical }) {
  const { prompt } = protocol;
  const choices = prompt.choices ?? [];

  if (historical) {
    return <div />;
  }

  return (
    <div className="main-message main-message-system">
      <p>
        {prompt.text}
        <br />
        {choices.map(([k, c]) => (
          <span key={k}>
            {k}. {c}
            <br />
          </span>
        ))}
      </p>
    </div>
  );
}

function _toTokens(text, tokens = {}) {
  return reactStringReplace(text, /({{[a-zA-Z0-9@#:-]+}})/, (match, index) => {
    const id = match.substr(2, match.length - 4);
    const token = tokens[id];
    if (!token) {
      return match;
    }
    switch (token.type) {
      case "player":
        if (token.isYou) {
          return <You token={token} key={index} />;
        }
        return <Player name={token.name} key={index} />;
      case "npc":
        return <Npc token={token} capitalize={true} key={index} />;
      case "exit":
        return <Exit direction={token.direction} key={index} />;
      default:
        return "Unknown";
    }
  });
}

function _toParagraphs(text, tokens = {}) {
  return (text ?? "")
    .split(/\n\n/gi)
    .map((t, i) => <p key={i}>{_toTokens(t, tokens)}</p>);
}

function Message({ protocol, historical }) {
  return useMemo(() => {
    switch (protocol.type) {
      case "action":
        return <ActionMessage protocol={protocol} historical={historical} />;
      case "description":
        return (
          <DescriptionMessage protocol={protocol} historical={historical} />
        );
      case "dialog":
        return <DialogMessage protocol={protocol} historical={historical} />;
      case "system":
        return <SystemMessage protocol={protocol} historical={historical} />;
      case "prompt":
        return <Prompt protocol={protocol} historical={historical} />;
      case "room":
        return <Room protocol={protocol} historical={historical} />;
      default:
        return _toParagraphs(protocol.message, protocol.tokens);
    }
  }, [protocol, historical]);
}

export default Message;
