export const ACTION_RECEIVE_ACTION_PROTOCOL = "ACTION_RECEIVE_ACTION_PROTOCOL";
export const ACTION_RECEIVE_DESCRIPTION_PROTOCOL = "ACTION_RECEIVE_DESCRIPTION_PROTOCOL";
export const ACTION_RECEIVE_DIALOG_PROTOCOL = "ACTION_RECEIVE_DIALOG_PROTOCOL";
export const ACTION_RECEIVE_PROMPT_PROTOCOL = "ACTION_RECEIVE_PROMPT_PROTOCOL";
export const ACTION_RECEIVE_ROOM_PROTOCOL = "ACTION_RECEIVE_ROOM_PROTOCOL";
export const ACTION_RECEIVE_SYSTEM_MESSAGE_PROTOCOL = "ACTION_RECEIVE_SYSTEM_MESSAGE_PROTOCOL";

export const receiveActionProtocol = (protocol) => {
  return {
    type: ACTION_RECEIVE_ACTION_PROTOCOL,
    protocol,
  }
}

export const receiveDescriptionProtocol = (protocol) => {
  return {
    type: ACTION_RECEIVE_DESCRIPTION_PROTOCOL,
    protocol,
  }
}

export const receiveDialogProtocol = (protocol) => {
  return {
    type: ACTION_RECEIVE_DIALOG_PROTOCOL,
    protocol,
  }
}

export const receivePromptProtocol = (protocol) => {
  return {
    type: ACTION_RECEIVE_PROMPT_PROTOCOL,
    protocol,
  }
}

export const receiveRoomProtocol = (protocol) => {
  return {
    type: ACTION_RECEIVE_ROOM_PROTOCOL,
    protocol,
  }
}

export const receiveSystemMessageProtocol = (protocol) => {
  return {
    type: ACTION_RECEIVE_SYSTEM_MESSAGE_PROTOCOL,
    protocol,
  }
}
