import "./App.css";
import { GameContextProvider } from "./Contexts/GameContext";
import { WebSocketContextProvider } from "./Contexts/WebSocketContext";
import MainView from "./Components/MainView";
import CommandInput from "./Components/CommandInput";
import { initialState, reducer } from "./State/Reducers";
import HeaderView from "./Components/HeaderView";

function App() {
  return (
    <article className="article-body">
      <GameContextProvider initialState={initialState} reducer={reducer}>
        <WebSocketContextProvider>
          <header>
            <HeaderView/>
          </header>
          <main>
            <MainView />
          </main>
          <footer>
            <CommandInput />
          </footer>
        </WebSocketContextProvider>
      </GameContextProvider>
    </article>
  );
}

export default App;
